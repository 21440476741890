import Vue from 'vue'

import Router from 'vue-router'
import Layout from '@/components/layout/Layout'

import User from '@/components/system/User'
import Role from '@/components/system/Role'
import Client from '@/components/system/Client'
import Channel from '@/components/system/Channel'


import Hcp from '@/components/admin/Hcp'
import Register from '@/components/admin/register'
import Config from '@/components/admin/Config'
import Account from '@/components/admin/account'
import Consent from '@/components/admin/Consent'
import Blacklist from '@/components/admin/Blacklist'
import Overclock from '@/components/admin/Overclock.vue'
import Realname from '@/components/admin/Realname'
import Realnamelog from '@/components/admin/Realnamelog'

// import Psr from '@/components/admin/Psr'
import TokenList from '@/components/log/tokenList'
import JingsocialTag from '@/components/log/jingsocialTag'
import ThirdClientApi from '@/components/log/thirdClientApi'
import ThirdPartyApi from '@/components/log/thirdPartyApi'
// import AktanaPushMessage from '@/components/log/aktanaPushMessage'
import JingSyncLog from '@/components/log/jingSyncLog.vue'
import WechatEvent from '@/components/log/wechatEvent'
import AdminAccountLog from '@/components/log/adminAccountLog'
import QrcodeLog from '@/components/log/qrcodeLog'
import Login from '@/components/admin/Login'

import Error from '@/components/error/Error'
import Error404 from '@/components/error/404'
import NoEmployess from '@/components/error/NoEmployess'
//以上为phase1上线路由

/*   */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'Main',
    //   redirect: '/management'
    // },
    {
      path: '/',
      name: 'login',
      component: Login,
      redirect: '/login',
    },
    {
      path: '/management',
      name: 'Admin',
      component: Layout,
      // component: resolve => require(['@/components/layout/Layout'],resolve),
      children: [
        {
          path: '/management/user',
          component: User,
          // component: resolve => require(['@/components/admin/User'],resolve),
          name: 'User',
          meta: [{ name: '管理员管理', pathName: 'user' }],
        },
        {
          path: '/management/hcp',
          component: Hcp,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Hcp',
          meta: [{ name: '账号管理', pathName: 'hcp' }],
        }, {
          path: '/management/blacklist',
          component: Blacklist,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Blacklist',
          meta: [{ name: '黑名单管理', pathName: 'blacklist' }],
        }, {
          path: '/management/overclock',
          component: Overclock,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Overclock',
          meta: [{ name: '高频禁用管理', pathName: 'overclock' }],
        },
        {
          path: '/management/realname',
          component: Realname,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Realname',
          meta: [{ name: '实名验证', pathName: 'realname' }],
        },
        {
          path: '/management/realnamelog',
          component: Realnamelog,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Realnamelog',
          meta: [{ name: '实名验证查询日志', pathName: 'realnamelog' }],
        },
        {
          path: '/management/client',
          component: Client,
          // component: resolve => require(['@/components/admin/User'],resolve),
          name: 'Client',
          meta: [{ name: '客户端管理', pathName: 'client' }],
        },
        {
          path: '/management/channel',
          component: Channel,
          // component: resolve => require(['@/components/admin/User'],resolve),
          name: 'Channel',
          meta: [{ name: '渠道管理', pathName: 'channel' }],
        }, {
          path: '/management/consent',
          component: Consent,
          // component: resolve => require(['@/components/admin/User'],resolve),
          name: 'Consent',
          meta: [{ name: 'Consent管理', pathName: 'consent' }],
        },
        {
          path: '/management/register',
          component: Register,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Register',
          meta: [{ name: 'DCR绑定日志', pathName: 'register' }],
        },
        {
          path: '/management/config',
          component: Config,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Config',
          meta: [{ name: '重复绑定日志', pathName: 'config' }],
        },
        {
          path: '/management/account',
          component: Account,
          // component: resolve => require(['@/components/admin/Hcp'],resolve),
          name: 'Account',
          meta: [{ name: '账号变更日志', pathName: 'account' }],
        },
        {
          path: '/management/role',
          component: Role,
          // component: resolve => require(['@/components/admin/Role'],resolve),
          name: 'Role',
          meta: [{ name: '角色管理', pathName: 'role' }],
        },
        {
          path: '/management/tokenList',
          component: TokenList,
          // component: resolve => require(['@/components/log/tokenList'],resolve),
          name: 'TokenList',
          meta: [{ name: 'token生成日志', pathName: 'tokenList' }],
        },
        {
          path: '/management/jingsocialTag',
          component: JingsocialTag,
          // component: resolve => require(['@/components/log/JingsocialTag'],resolve),
          name: 'JingsocialTag',
          meta: [{ name: '标签推送日志', pathName: 'jingsocialTag' }],
        },
        {
          path: '/management/wechatEvent',
          component: WechatEvent,
          // component: resolve => require(['@/components/log/WechatEvent'],resolve),
          name: 'WechatEvent',
          meta: [{ name: '微信推送日志', pathName: 'wechatEvent' }],
        },
        {
          path: '/management/adminAccountLog',
          component: AdminAccountLog,
          // component: resolve => require(['@/components/log/AdminAccountLog'],resolve),
          name: 'AdminAccountLog',
          meta: [{ name: '管理员变更日志', pathName: 'adminAccountLog' }],
        },
        {
          path: '/management/qrcodeLog',
          component: QrcodeLog,
          // component: resolve => require(['@/components/log/QrcodeLog'],resolve),
          name: 'QrcodeLog',
          meta: [{ name: '生成HCP二维码日志', pathName: 'qrcodeLog' }],
        },
        {
          path: '/management/thirdClientApi',
          component: ThirdClientApi,
          // component: resolve => require(['@/components/log/ThirdClientApi'],resolve),
          name: 'ThirdClientApi',
          meta: [{ name: '客户端调用日志', pathName: 'thirdClientApi' }],
        },
        {
          path: '/management/thirdPartyApi',
          component: ThirdPartyApi,
          // component: resolve => require(['@/components/log/ThirdPartyApi'],resolve),
          name: 'ThirdPartyApi',
          meta: [{ name: '三方接口调用日志', pathName: 'thirdPartyApi' }],
        },{
          path: '/management/jingSyncLog',
          component: JingSyncLog,
          // component: resolve => require(['@/components/log/ThirdPartyApi'],resolve),
          name: 'JingSyncLog',
          meta: [{ name: 'Jing同步日志', pathName: 'jingSyncLog' }],
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      // component: resolve => require(['@/components/admin/Login'],resolve),
    },
    {
      path: '/error',
      name: 'error',
      component: Error,
      // component: resolve => require(['@/components/error/Error'],resolve),
    },
    {
      path: '/noEmployess',
      name: 'noEmployess',
      component: NoEmployess,
      // component: resolve => require(['@/components/error/NoEmployess'],resolve),
    },
    {
      path: '*',
      name: 'Not Found',
      component: Error404,
      meta: {
        title: '404',
      },
    },
  ],
})
