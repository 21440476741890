<template>
  <div>
    <el-row class="hcp_list">
      <!-- <span v-if="showSearch" class="right_icon" @click="shSearch">
        收起
        <i class="el-icon-d-arrow-right" style="display:inline-block;transform:rotate(90deg);"></i>
      </span>
      <span v-if="!showSearch" class="right_icon" @click="shSearch">
        展开
        <i class="el-icon-d-arrow-right" style="display:inline-block;"></i>
      </span> -->
      <el-col :span="20" :offset="1">
        <el-form
          v-if="showSearch"
          id="top_form_select"
          ref="HcpQueryForm"
          :model="HcpQueryForm"
          style="margin-top:-10px;"
          label-width="75px"
          class="form"
        >
          <!-- 筛选条件 -->
          <el-row>
            <!-- <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="类型"
                  prop="type"
                  label-width="60px"
                >
                  <el-input
                    v-model="HcpQueryForm.title"
                    class="bottom_input"
                    prop="type"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col> -->
            <el-col :span="5">
              <div class="grid-content bg-purple">
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="union id"
                  prop="union_id"
                  label-width="75px"
                >
                  <el-input
                    v-model="HcpQueryForm.union_id"
                    class="bottom_input"
                    prop="union_id"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <div class="grid-content bg-purple-light">
                  <el-form-item
                    label="状态"
                    prop="description"
                    label-width="60px"
                  >
                    <el-select
                      v-model="HcpQueryForm.status"
                      clearable
                      placeholder="请选择"
                    >
                      <!-- <el-option label="全部" value="null"></el-option> -->
                      <el-option
                        v-for="row in statuss"
                        v-bind:key="row.value"
                        :label="row.name"
                        :value="row.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                </div>
              </div>
            </el-col>
    <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <div class="grid-content bg-purple-light">
                 
                 <el-form-item
                    label="创建时间"
                    prop="description"
                    label-width="75px"
                    style="margin-bottom:0px;"
                  >
                    <div class="grid-content bg-purple">
                      <div id="block" class="block" style="width:60%">
                        <el-date-picker
                          v-model="HcpQueryForm.bindTimeFrom"
                          :picker-options="bind_start_date"
                          type="date"
                          style="width:100%"
                          placeholder="from"
                        ></el-date-picker>
                      </div>
                      <div
                        id="block"
                        class="block"
                        style="margin-left:60%;margin-top:-30px;width:70%;padding-left:22px;"
                      >
                        <el-date-picker
                          v-model="HcpQueryForm.bindTimeTo"
                          :picker-options="bind_end_date"
                          type="date"
                          style="width:100%"
                          placeholder="to"
                        ></el-date-picker>
                      </div>
                    </div>
                  </el-form-item>

                </div>
              </div>
            </el-col>
            


          </el-row>
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="getSearchList()"
            >搜索</el-button
          >
          <el-button
            type="primary"
            class="sub hcp_top_form_sub"
            plain
            @click="resend()"
            >批量重发</el-button
          >
        </el-form>
        <!-- 客户列表 -->
        <el-form
          id="hcp_list"
          ref="HcpQueryForm"
          :model="HcpQueryForm"
          label-width="70px"
          class="form"
          style="margin-top:10px;"
        >
          <div class="block">
            <el-table
              v-loading="loading"
              ref="table_list"
              :data="hcpList"
              :default-sort="{ prop: 'createTime', order: 'descending' }"
              style="width: 100%;"
              header-row-class-name="tableHead"
            >
              <el-table-column
                :show-overflow-tooltip="false"
                label="类型"
                prop="type"
                align="center"
                min-width="70"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                    >{{ scope.row.type }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="入参"
                prop="type"
                align="center"
                min-width="150"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                    >{{ scope.row.query }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="false"
                label="union id"
                prop="union id"
                align="center"
                min-width="100"
                max-width="10%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;white-space:nowrap;width:100%; text-align:center;"
                    >{{ scope.row.unionId }}</span
                  >
                </template>
              </el-table-column>
              
              <el-table-column
                :show-overflow-tooltip="false"
                label="发送次数"
                prop="execNum"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                    >{{ scope.row.execNum }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                :show-overflow-tooltip="false"
                label="创建时间"
                prop="createTime"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                    >{{ scope.row.createTime|dt }}</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                :show-overflow-tooltip="false"
                label="备注"
                prop="body"
                align="center"
                min-width="150"
                max-width="15%"
              >
                <template slot-scope="scope">
                  <!-- hover时显示详细信息 -->
                  <el-popover trigger="hover" placement="top">
                    <p>{{ scope.row.comment }}</p>
                    <div slot="reference" class="name-wrapper">
                      <span
                        style="
                          display: inline-block;
                          white-space: nowrap;
                          width: 100%;
                          text-align: left;
                        "
                        >{{ scope.row.comment }}</span
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>

              <el-table-column
                :show-overflow-tooltip="false"
                label="状态"
                prop="status"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                    >{{ scope.row.status|s }}</span
                  >
                </template>
              </el-table-column>


              <el-table-column
                :show-overflow-tooltip="false"
                label="操作"
                prop="sms_consent"
                align="center"
                min-width="90"
                max-width="7%"
              >
                <template slot-scope="scope">
                  <span
                    style="display:inline-block;width:100%; text-align:center;"
                  >
                    <el-button
                      v-if="
                          scope.row.status=='failed'
                      "
                      type="primary"
                      class="sub_review sub"
                      style="width:54px;"
                      plain
                      @click="resend(scope.row.id)"
                    >
                      重发
                    </el-button>
                  </span>
                </template>
              </el-table-column>

            </el-table>
            <!-- 列表下方分页信息 -->
            <el-pagination
              :current-page="currentPage"
              :page-sizes="[20, 50, 100]"
              :page-size="parseInt(page.limit)"
              :total="page.total"
              style="margin:center;margin-top:12px;margin-bottom:12px;height:46px;text-align:center;"
              layout="total,sizes,prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
            <!-- :current-page="page.offset" -->
          </div>
          <!-- 筛选列表容器 -->
          <!-- <el-transfer
            v-if="showShaixuan"
            :props="{key: 'id',label: 'name'}"
            :titles="['筛选']"
            :left-default-checked="checkedShaixuan"
            :data="noData"
            style="width:130px;"
            @left-check-change="handleChange"
          >
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="determineShaixuan"
            >确定</el-button>
            <el-button
              slot="left-footer"
              type="primary"
              class="transfer-footer sub_review sub"
              size="small"
              @click="cancelShaixuan"
            >取消</el-button>
          </el-transfer> -->
        </el-form>
      </el-col>
    </el-row>


  </div>
</template>
<script>

import {util} from 'co-utility'

export default {
  components: {},
  data() {
    return {
      //绑定时间选择器关联
      bind_start_date: {
        //开始时间范围限定
        disabledDate: (time) => {
          if (this.HcpQueryForm.bindTimeTo) {
            return time.getTime() > this.HcpQueryForm.bindTimeTo;
          }
          return time.getTime() < Date.now() - 8.64e11;
        },
      },
      bind_end_date: {
        //结束时间范围限定
        disabledDate: (time) => {
          return time.getTime() <= this.HcpQueryForm.bindTimeFrom - 1;
        },
      },
      //filter显示状态
      showSearch: true,
      //初始筛选选中状态
      item: ["workplaceName", "inviter"],
      //table列显示
      tagStatus: {
        name: true,
        mdmCode: true,
        clientName: true,
        Unionid: true,
        OpenId: true,
        extUserId: false,
        workplaceName: true,
        inviter: true,
        nickname: true,
        department: false,
        isFollow: false,
        bindSource: false,
        bindTime: false,
        createTime: false,
        bindState: true,
        validateState: false,
      },
      //列表Loading
      loading: false,
      //筛选初始赋值
      checkedShaixuan: ["workplaceName", "inviter"],
      //筛选列表元素，disabled为不可选中
      noData: [
        { id: "name", name: "姓名", disabled: true },
        { id: "Unionid", name: "UnionID", disabled: true },
        { id: "OpenId", name: "OpenID", disabled: true },
        { id: "mdmCode", name: "MDM code", disabled: true },
        { id: "clientName", name: "clientName", disabled: true },
        { id: "workplaceName", name: "工作地点" },
        { id: "nickname", name: "微信昵称" },
        { id: "extUserId", name: "extUserId" },
        { id: "department", name: "部门" },
        { id: "inviter", name: "邀请人" },
        { id: "isFollow", name: "关注状态" },
        { id: "bindState", name: "绑定状态" },
        { id: "validateState", name: "MDM状态" },
        { id: "bindTime", name: "绑定时间" },
        { id: "createTime", name: "创建时间" },
        { id: "bindSource", name: "客户来源" },
      ],
      //筛选弹窗显示隐藏
      showShaixuan: false,
      //客户列表存值数组
      hcpList: [],
      statuss: [
        {name:'未开始',value:'default'},
        {name:'进行中',value:'inprogress'},
        {name:'已完成',value:'done'},
        {name:'失败',value:'failed'},
      ], //科室数据
      //分页默认设置
      page: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      order: "+",
      sortProp: "createTime",
      //初始显示页码
      currentPage: 1,
      oldSearch: {
        name: null, //客户名称
        unionId: null,
        openId: null,
        mobile: null, 
        extUserId: null,
        mdmCode: null, //MDMId
        clientName: null,
        nickname: null,
        workplaceName: null, //工作地点
        inviter: null, //inviter
        bindState: null,
        validateState: null, //状态
        isFollow: null,
        bindSource: null,
        department: null,
        bindTimeTo: null,
        bindTimeFrom: null,
      },
      //文章查询
      HcpQueryForm: {
         union_id: null,
        status: null,
        bindTimeTo: null,
        bindTimeFrom: null,
      },
      commentMessage: "",
      errorMessage: "",
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    // 获取存储在本地的筛选列表历史
    // this.tagStatus = this.$global.getShaixuan("hcpshaixuan")
    //   ? this.$global.getShaixuan("hcpshaixuan")
    //   : this.tagStatus;
    // this.getCheckedPsrStatus();
    //获取客户列表
    console.log(111)
    const data = {
      offset: this.page.offset,
      limit: this.page.limit,
      order: this.order + this.sortProp,
    };
    this.getHcpList(data);

    // this.getSearchList();
  },
  mounted() {},
  methods: {
    toAccount(parms) {
      const href = this.$router.resolve({
        name: "Account",
        query: { id: parms.id },
      });
      window.open(href.href, "_blank");
    },
    toRegister(parms) {
      const href = this.$router.resolve({
        name: "Register",
        query: { id: parms.id },
      });
      window.open(href.href, "_blank");
    },
    shSearch() {
      this.showSearch = !this.showSearch;
    },
    //读取筛选列表信息，更新筛选选择状态
    getCheckedPsrStatus() {
      this.checkedShaixuan = [];
      for (const i in this.tagStatus) {
        if (this.tagStatus[i] == true) {
          this.checkedShaixuan.push(i);
        }
      }
      this.item = this.checkedShaixuan;
    },
    //筛选勾选触发函数
    handleChange(value) {
      this.item = value;
    },
    //确定筛选
    determineShaixuan() {
      //更新筛选状态
      this.checkedShaixuan = this.item;
      this.showShaixuan = false;
      for (const attr in this.tagStatus) {
        this.tagStatus[attr] = false;
      }
      for (let i = 0; i < this.item.length; i++) {
        this.tagStatus[this.item[i]] = true;
      }
      //存储筛选信息到本地
      this.$global.setShaixuan("hcpshaixuan", this.tagStatus);
    },
    cancelShaixuan() {
      //取消筛选
      this.showShaixuan = false;
    },

    formatConsentStatus(rows) {
      if (!rows || rows.length == 0) {
          this.loading=false;
          let test = JSON.parse(JSON.stringify(rows));
          this.hcpList = test;
          this.$forceUpdate();
          return
      }
      let union_ids = [];
      for (let row of rows) {
        union_ids.push(row.id);
      }
      let query = {
        data: {
          union_ids,
        },
      };
      this.$global
        .httpPostWithToken(this, "/hcp/rc/consentStatus", query)
        .then((res) => {
          this.loading = false;
          console.log("consent --", res);
          let consents = res.data;
          //row.smsConsent=res.data.status;
          for (let row of rows) {
            for (let consent of consents) {
              if (row.id == consent.unionId) {
                row.smsConsent = consent.sms;
                row.mobileConsent = consent.mobile;
              }
            }
          }

          let test = JSON.parse(JSON.stringify(rows));
          this.hcpList = test;
          this.$forceUpdate();

          //   console.log('rows',rows)
          //  setTimeout(()=>{
          //      this.$forceUpdate();
          //  },100)
        })
        .catch((error) => {});
    },
    //获取客户列表
    getHcpList(data) {
      for (var i in data) {
        if (data[i]=='' || (!data[i] && data[i]!=0)) {
          delete data[i]
        }
      }
      
      console.log('query-',data)
      this.loading = true;
      this.$global
        .httpPostWithToken(this, "/log/jingSyncList", {data:data})
        .then((res) => {
          console.log('列表',res);
          // res=res.data;
          // if(res.err)return;
          //this.hcpList = res.data;
          this.page.total = res.data.total;
          this.hcpList = res.data.data;
           this.$forceUpdate();
          // this.$nextTick(() => {
          //   this.formatConsentStatus(res.data);
          //   this.$forceUpdate();
          // });

          this.loading = false;
          
        })
        .catch((error) => {
          this.loading = false;
        });
    },

 

    resend(id) {
      let data={};
      let self=this;
      if(id){
        data.id=id;
      }else{
        data = {
          union_id: this.HcpQueryForm.union_id,
          status: this.HcpQueryForm.status,
          bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
          bindTimeTo: this.HcpQueryForm.bindTimeTo,
        };
      }

     for (var i in data) {
        if (data[i]=='' || (!data[i] && data[i]!=0)) {
          delete data[i]
        }
      }

      const h = this.$createElement;
      var dom = document.getElementById("foo");
       this.$global
        .httpPostWithToken(this, "/log/jingSyncResend", {data:data})
        .then((res) => {
          console.log('列表',res);
      
            this.$msgbox({
        title: "提示",
        message: h("div", { style: "" }, [
          h(
            "p",
            { style: "margin-bottom:10px;font-size:13px;" },
            `已加入发送对列，等待执行`
          ),
          h(
            "div",
            {
              class: {
                textarea: true,
                bar: false,
              },
            },
            [
   
            ]
          ),
        ]),
        showCancelButton: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "",
        autofocus: true,
        type: "warning",
        center: true,
        beforeClose(action, instance, done) {
            self.getSearchList();
            done();
        },
      })


       

          
        })
    },
    //点击分页时触发,改变列表内容
    handleSizeChange(val) {
      this.HcpQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = 1;
      this.page.limit = val;
      this.page.offset = 0;
       const data = {
        union_id: this.HcpQueryForm.union_id,
        status: this.HcpQueryForm.status,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }

      this.getHcpList(data);
    },
    //按页数展示
    handleCurrentChange(val) {
      this.HcpQueryForm = JSON.parse(JSON.stringify(this.oldSearch));
      this.currentPage = val;
      this.page.offset = (val - 1) * this.page.limit;
       const data = {
        union_id: this.HcpQueryForm.union_id,
        status: this.HcpQueryForm.status,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }

      this.getHcpList(data);
    },
    //筛选文章列表表头
    showNext() {
      this.showShaixuan = true;
      this.$nextTick(() => {
        var checked = document.getElementsByClassName("is-disabled");
        for (const i in checked) {
          if (checked[i].localName == "span") {
            checked[i].classList.add("is-checked");
          }
        }
      });
    },
    //执行搜索
    getSearchList() {
      console.log('search',this.HcpQueryForm);
      this.oldSearch = JSON.parse(JSON.stringify(this.HcpQueryForm));
      this.page.offset = 0;
      this.$refs.table_list.clearSort();
      //点击搜索后恢复sort排序默认状态样式
      for (const attr in this.$refs.table_list.columns) {
        if (this.$refs.table_list.columns[attr].property == "createTime") {
          this.$refs.table_list.columns[attr].order = "descending";
        }
      }
      const data = {
        union_id: this.HcpQueryForm.union_id,
        status: this.HcpQueryForm.status,
        bindTimeFrom: this.HcpQueryForm.bindTimeFrom,
        bindTimeTo: this.HcpQueryForm.bindTimeTo,
        offset: this.page.offset,
        limit: this.page.limit,
        order: this.order + this.sortProp,
      };
      if (this.HcpQueryForm.bindTimeFrom) {
        data.bindTimeFrom = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeFrom).getTime() +
              8 * 60 * 60 * 1000
          )
        );
      }
      if (this.HcpQueryForm.bindTimeTo) {
        data.bindTimeTo = JSON.stringify(
          new Date(
            new Date(this.HcpQueryForm.bindTimeTo).getTime() +
              31 * 60 * 60 * 1000 +
              59 * 60 * 1000 +
              59 * 1000
          )
        );
      }

      this.getHcpList(data);
      this.currentPage = 1;
    },

    


  },
  filters:{ //钩子
    dt(val){  //过滤器函数的名字filterOne，val是传递过来的参数
      return util.formatDate(val,'YYYY-MM-DD HH:mm:ss')  //操作之后将结果返回出去
    },
    s(val) {
       //此处val是经过上一个过滤器filterOne操作之后的返回的结果，unit是参进来的参数
      //return val+unit
      switch(val){
        case 'failed':return '失败';
        case 'ingrogress':return '进行中';
        case 'done':return '已完成';
        default:return'未开始'
      }
    }
  }
};
</script>
<style scoped>
.textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  outline: none !important;
}
.textarea:focus {
  outline: normal !important;
  border: "1px solid #409EFF";
}
/* TOP form样式 */
.hcp_list {
  margin-top: 8px;
  width: 93%;
}
.hcp_list > el-col {
  max-width: 83.3%;
}
.form {
  min-width: 400px;
  margin-bottom: 28px;
}
.form_header {
  text-align: left;
}
#top_form_select .el-form-item {
  margin-bottom: 0px;
}
#top_form_select img {
  width: 22px;
  vertical-align: -4px;
}
#block .el-input__prefix > .el-input__icon {
  position: absolute;
  left: 210px;
  top: 0;
  height: 100%;
  width: 22px;
  font-size: 22px;
}
#top_form_select .el-input input {
  width: 100%;
  height: 24px;
}
#top_form_select .el-button {
  margin-top: 24px;
}
/* 文章详情样式 */
.hcp_list #hcp_list .el-button--primary {
  background-color: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list #hcp_list .el-button--primary:hover {
  background-color: #00857c !important;
  border-color: #00857c !important;
  color: #ffffff !important;
}
#hcp_list {
  box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.33);
}
#hcp_list .el-form-item {
  margin-left: -10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 100%;
}
#hcp_list img {
  margin-left: 6px;
  display: inline-block;
  width: 16px;
}
.caozuo {
  font-size: 16px;
  color: #909399;
  font-weight: 400;
  border-bottom: 1px solid #909399;
  margin-right: 30px;
}
.caozuo:hover {
  cursor: pointer;
  color: #00857c;
  border-bottom-color: #00857c;
}
.shaixuan:hover {
  cursor: pointer;
}
.sub {
  width: 44px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: rgba(240, 242, 245, 1);
  color: #00857c;
  padding: 0;
}
.sub:hover {
  background: rgba(240, 242, 245, 1);
  color: #00857c;
}
.hcp_list .hcp_top_form_sub {
  width: 104px;
  height: 27px;
  background: #00857c;
  border-color: #00857c;
  color: #ffffff;
}
.hcp_list .hcp_top_form_sub:hover {
  background: #00857c !important;
  color: #ffffff !important;
}
.hcp_list .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #00857c !important;
  border-color: #00857c !important;
  color: #fff;
}
.hcp_list .el-button.is-active,
.el-button.is-plain:active {
  color: #fff;
  background: #00857c !important;
}
.hcp_list .el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #00857c !important;
  color: #fff;
}
.category_select {
  border: 1px solid #eaeefb;
  padding: 10px 30px 10px 10px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.add_category_row {
  height: 0;
  overflow: hidden;
  transition: all 400ms;
  background: #f9fafc;
}
.showEdit {
  height: 185px;
}
.add_category {
  background: #f9fafc;
  padding: 10px 30px 0px 10px;
  border: 1px solid #eaeefb;
  border-top: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.cell {
  text-align: center;
}
.hcp_list .el-table__header-wrapper {
  background: #f5f7fa;
}
</style>
